* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Cairo", sans-serif !important;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #ffb347;
}

.background-radial-gradient {
  background-color: #204f7d;
  background-image: radial-gradient(
      650px circle at 0% 0%,
      #407db8 15%,
      #3a73a6 35%,
      #2d5f88 75%,
      #285a82 80%,
      transparent 100%
    ),
    radial-gradient(
      1250px circle at 100% 100%,
      #5893c1 15%,
      #3a73a6 35%,
      #2d5f88 75%,
      #285a82 80%,
      transparent 100%
    );
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#ff8000, #ffb347);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#ff8000, #ffb347);
  overflow: hidden;
}

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.836) !important;
  backdrop-filter: saturate(200%) blur(25px);
}

.custom-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: #eee;
  overflow: hidden;
}

.custom-container-card {
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 50% !important;
}

.ant-notification {
  font-family: "Cairo", sans-serif !important;
}

.sign-in-error-message {
  color: red;
  font-size: 12px;
  margin-top: -20px;
}

.logout-btn {
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 120px;
}

.title-text {
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 2rem;
}

.logo-header {
  width: 100px;
  margin: -10px;
}

.section .error {
  font-size: 150px;
  color: #204f7d;
  text-shadow: 1px 1px 1px #3a73a6, 2px 2px 1px #3a73a6, 3px 3px 1px #3a73a6, 4px 4px 1px #3a73a6,
    5px 5px 1px #3a73a6, 6px 6px 1px #3a73a6, 7px 7px 1px #3a73a6, 8px 8px 1px #3a73a6,
    25px 25px 8px rgba(0, 0, 0, 0.2);
}

.page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 600;
  color: #444;
}

.back-home {
  display: inline-block;
  border: 2px solid #222;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 15px 15px -11px rgba(0, 0, 0, 0.4);
  background: #222;
  border-radius: 6px;
}

.back-home:hover {
  background: #131313;
  color: #ddd;
}

.sub-titlebaground {
  background: #00000023;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  margin: auto;
  width: 382px;
  height: 118px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: fit-content;
}

.sub-titlebaground.home {
  width: 525px;
  height: 291px;
  padding: 18px;
}

.sub-titlebaground:hover {
  background: #00000075;
  transition: 0.3s;
  box-shadow: 0 0 10px #ff8000;
}

.continer-activities {
  margin-top: 6rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1em;
}

.editable-field {
  display: flex;
  justify-content: space-between;
}

.editable-input {
  border: none;
  font-size: inherit;
  background: inherit;
  color: inherit;
}

.editable-input:focus {
  outline: none;
  background: white;
  color: #545454;
}

.edit-button {
  cursor: pointer;
}

.hide {
  visibility: hidden !important;
}

.editing {
  border: black 1px solid;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0 0.5rem;
  border-radius: 10px;
}

.submition-card {
  font-size: 1.2em;
  background-color: #0000000d;
  border-radius: 0.5em;
  padding: 5px;
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
}

.submition-card:hover {
  box-shadow: 0 0 0.5em #ff8000;
}

@media screen and (max-width: 768px) {
  #radius-shape-1 {
    display: none;
  }
  #radius-shape-2 {
    display: none;
  }
  .custom-container-card {
    width: 100% !important;
    height: 100%;
    border: 0px;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  .sub-titlebaground {
    max-width: 90%;
  }
}

.waves > use {
  animation: move-forever 2s -2s linear infinite;
}

.waves > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 6s;
}
.waves > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 3s;
}

@keyframes move-forever {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

.svg {
  /* at the very btm not working */
  /* and if the div is empt also make it in very botm */
  position: absolute;
  width: 100%;
  height: 30vw;
  max-height: 80px;
}

.form-label {
  font-size: 1.2rem;
  font-weight: 600;
}

:root {
  --spinkit-size: 10.6em;
  --spinkit-spinner-color: #204f7d;
  --binadox-preloader-color--red: #ff8000;
  --border-width: 1px;
  --spinner-whidth: calc((100% - 4 * 3px) / 3);
  --delayRange: 0.4s;
}

.grid-container {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
}

section {
  flex: 1 1 25%;
}

.sk-cube-grid {
  width: var(--spinkit-size);
  height: var(--spinkit-size);
  margin: auto;
  background-color: transparent;
}

.sk-cube {
  box-sizing: border-box;
  width: var(--spinner-whidth);
  height: var(--spinner-whidth);
  background-color: var(--spinkit-spinner-color);
  float: left;
  animation: sk-cube-grid-scale-delay 1.3s infinite ease-in-out;
  margin: 3px;
  border-radius: 3px;
}

.sk-cube-3 {
  animation-delay: var(--delayRange);
  margin-top: 0;
  margin-right: 0;
  background-color: var(--binadox-preloader-color--red);
  border: 10px solid wheat;
}

.sk-cube-1 {
  animation-delay: calc(var(--delayRange) * 0.5);
  margin-top: 0;
  margin-left: 0;
}

.sk-cube-2 {
  animation-delay: calc(var(--delayRange) * 0.75);
  margin-top: 0;
}

.sk-cube-4 {
  animation-delay: calc(var(--delayRange) * 0.25);
  margin-left: 0;
}

.sk-cube-5 {
  animation-delay: calc(var(--delayRange) * 0.5);
}

.sk-cube-6 {
  animation-delay: calc(var(--delayRange) * 0.75);
  margin-right: 0;
}

.sk-cube-7 {
  animation-delay: 0s;
  margin-bottom: 0;
  margin-left: 0;
}

.sk-cube-8 {
  animation-delay: calc(var(--delayRange) * 0.25);
  margin-bottom: 0;
}

.sk-cube-9 {
  animation-delay: calc(var(--delayRange) * 0.5);
  margin-bottom: 0;
  margin-right: 0;
}

@keyframes sk-cube-grid-scale-delay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}

.form-outline .form-control ~ .form-label {
  padding-top: 0px !important;
}
